import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../app/services/login/login.service';
import { AccountService } from '../app/services/auth/account.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  Driver:any;
  account: any;
  theme : boolean=false;
  constructor(  private loginService: LoginService, private accountService: AccountService,private platform: Platform,       private menu: MenuController,public navController: NavController,private translate: TranslateService) {
    this.initializeApp();
    this.sideMenu();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      if (Capacitor.isPluginAvailable('StatusBar')) {
        await StatusBar.setStyle({ style: Style.Default });
      }
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        await SplashScreen.hide();
      }
    });
    this.initTranslate();
    document.body.classList.add('dark');
    
  }

  ngOnInit() {
    this.accountService.identity().then(account => {
              if (account === null) {
                this.goBackToHomePage();
              } else {
                this.account = account;
              }
            });
           
          }
          isAuthenticated() {
           return this.accountService.isAuthenticated();
         }
   logout() {
     this.loginService.logout();
     this.goBackToHomePage();
   }
   private goBackToHomePage(): void {
     this.navController.navigateBack('');
   }

  changeTheme($event) {
    
    if (this.theme){
      
      document.body.classList.add('dark');
      this.theme=false
    }
    else {
      
      document.body.classList.remove('dark');
      this.theme=true
    }
    
  };
  initTranslate() {
    const enLang = 'en';

    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang(enLang);

    if (this.translate.getBrowserLang() !== undefined) {
      this.translate.use(this.translate.getBrowserLang());
    } else {
      this.translate.use(enLang); // Set your language here
    }
  }

  sideMenu() {
    this.Driver = [
    
      {
        title: 'My-Profile',
        url: '/my-profile',
        icon: 'person',
      },
      // {
      //   title: 'My-Drivers',
      //   url: '/tabs/entities/driver',
      //   icon: 'phone-portrait',
      // },
      {
        title: 'Expenses',
        url: '/tabs/entities/expenses',
        icon: 'car',
      },
      {
        title: 'Truck Fuel',
        url: '/tabs/entities/truck-fuel',
        icon: 'speedometer',
      },
      {
        title: 'Vehicle Maintenance',
        url: '/tabs/entities/truck-maintenance',
        icon: 'bookmark',
      },
      // {
      //   title: 'Driver',
      //   url: '/tabs/entities/driver',
      //   icon: 'logo-flickr',
      // },
      // {
      //   title: 'Shipment',
      //   url: '/tabs/entities/shipment',
      //   icon: 'card',
      // },
    ];

  }

  openactivities(){
    this.navController.navigateForward('tabs/home')
  }

  // openanalytics(){
  //   this.navController.navigateForward('tabs/account')
  // }

  // openinsights(){
  //   this.navController.navigateForward('tabs/insights')
  // }
}
