import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule) },
  { path: 'tabs', loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'signup', loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule) },
  { path: 'accessdenied', redirectTo: '', pathMatch: 'full' },
  // {
  //   path: 'reports',
  //   loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule)
  // },
  // {
  //   path: 'planning',
  //   loadChildren: () => import('./planning/planning.module').then( m => m.PlanningPageModule)
  // },
  // {
  //   path: 'insights',
  //   loadChildren: () => import('./insights/insights.module').then(m => m.InsightsPageModule),
  // },
  {
    path: 'password',
    loadChildren: () => import('./password/password.module').then(m => m.PasswordPageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
  },
  {
    path: 'profile-setting',
    loadChildren: () => import('./profile-setting/profile-setting.module').then(m => m.ProfileSettingPageModule),
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule),
  },  {
    path: 'diagnostics',
    loadChildren: () => import('./diagnostics/diagnostics.module').then( m => m.DiagnosticsPageModule)
  },

  // {
  //   path: 'vehicle-routing',
  //   loadChildren: () => import('./vehicle-routing/vehicle-routing.module').then( m => m.VehicleRoutingPageModule)
  // },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
